import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Flex,
  Grid,
  GridItem,
  Text,
  Box,
  Spacer,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { maxW } from '../assets/config';
import JsonLd from '../components/JsonLd';

const TeamPage = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Layout bg="black">
      <Seo
        title="Choker Joker's Team"
        description="ChokerJokerのチーム紹介ページです。"
      />
      <JsonLd
        title="Choker Joker's Team"
        description="ChokerJokerのチーム紹介ページです。"
      />
      <Box maxW={maxW} mx="auto">
        <StaticImage src="../images/kv_team.png" alt="Choker Joker's Team" />
        <Box bg="black" p={8}>
          <Flex direction="column" gap={8}>
            {/* メンバー1 */}
            <Flex gap={4} justify="center" alignItems="center">
              <Box w="30%">
                <StaticImage src="../images/john_img.png" alt="" />
              </Box>
              <Flex justify="center" alignItems="center">
                <Box color="white" p={{ base: 0, md: 4 }} fontWeight="bold">
                  <Text fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}>
                    John(ジョン)
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthDay: 2/26
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthPlace: {isMobile ? <br /> : null}神奈川県川崎市
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    Charge: Producer,
                    <br />
                    Music Maker
                  </Text>
                </Box>
              </Flex>
              <Spacer />
              <Box w="30%">
                <StaticImage src="../images/th_sub1.png" alt="" />
              </Box>
            </Flex>
            {/* メンバー2 */}
            <Flex gap={4} justify="center" alignItems="center">
              <Box w="30%">
                <StaticImage src="../images/udacchi_img.png" alt="" />
              </Box>
              <Flex justify="center" alignItems="center">
                <Box color="white" p={{ base: 0, md: 4 }} fontWeight="bold">
                  <Text fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}>
                    Udacchi(うだっち)
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthDay: 11/21
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthPlace: {isMobile ? <br /> : null}神奈川県横浜市
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    Charge: Music Maker
                  </Text>
                </Box>
              </Flex>
              <Spacer />
              <Box w="30%">
                <StaticImage src="../images/th_sub2.png" alt="" />
              </Box>
            </Flex>
            <Box h="2px" bg="white" />
            <Flex gap={4} justify="center" alignItems="center">
              <Box w="30%">
                <StaticImage src="../images/ni-suke1_img.png" alt="" />
              </Box>
              <Flex justify="center" alignItems="center">
                <Box color="white" p={{ base: 0, md: 4 }} fontWeight="bold">
                  <Text fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}>
                    Ni-suke(にいすけ)
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthDay: 5/24
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthPlace: {isMobile ? <br /> : null}埼玉県上福岡市
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    Charge: Photographer
                  </Text>
                </Box>
              </Flex>
              <Spacer />
              <Box w="30%">
                <StaticImage src="../images/ni-suke2_img.png" alt="" />
              </Box>
            </Flex>
            {/* メンバー2 */}
            <Flex gap={4} justify="center" alignItems="center">
              <Box w="30%">
                <StaticImage src="../images/maru1_img.png" alt="" />
              </Box>
              <Flex justify="center" alignItems="center">
                <Box color="white" p={{ base: 0, md: 4 }} fontWeight="bold">
                  <Text fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}>
                    Maru(まる)
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthDay: 4/8
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthPlace: {isMobile ? <br /> : null}埼玉県川越市
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    Charge: <br />
                    Choker Maker,
                    <br />
                    PhotoGrapher
                  </Text>
                </Box>
              </Flex>
              <Spacer />
              <Box w="30%">
                <StaticImage src="../images/maru2_img.png" alt="" />
              </Box>
            </Flex>
            {/* メンバー5 */}
            <Flex gap={4} justify="center" alignItems="center">
              <Box w="30%">
                <StaticImage src="../images/sala1_img.png" alt="" />
              </Box>
              <Flex justify="center" alignItems="center">
                <Box color="white" p={{ base: 0, md: 4 }} fontWeight="bold">
                  <Text fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}>
                    Sala(サラ)
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthDay: 2/2
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    BirthPlace: {isMobile ? <br /> : null}埼玉県川越市
                  </Text>
                  <Text fontSize={{ base: 'md', sm: 'xl', md: '3xl' }}>
                    Charge: {isMobile ? <br /> : null}Choker Maker
                  </Text>
                </Box>
              </Flex>
              <Spacer />
              <Box w="30%">
                <StaticImage src="../images/sala2_img.png" alt="" />
              </Box>
            </Flex>
            <Flex justify="center" alignItems="center">
              <Box w={isMobile ? '55%' : 'full'} textAlign="center">
                <StaticImage src="../images/team_icon.png" alt="" />
              </Box>
            </Flex>
          </Flex>
          <chakra.hr bg="white" my={20} />
          <Flex
            direction="column"
            gap={8}
            px={8}
            justify="center"
            w="full"
            mx="auto"
          >
            <Box w="full" textAlign="center">
              <StaticImage
                src="../images/kv_team_bottom.png"
                alt="Choker Jokers"
              />
            </Box>
            <chakra.hr bg="white" my={8} />
            <Flex justify="flex-end" alignItems="center" mb={10}>
              <Box w="40%">
                <StaticImage src="../images/team_text.png" alt="Choker Joker" />
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Layout>
  );
};

export default TeamPage;
